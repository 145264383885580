.block-form {
    border-radius: 3px;
    border: solid 1px #d4dce3;
    padding: 15px;
    margin-bottom: 50px;
    .title {
        font-size: 25px;
        color: #0091ff;
        padding-bottom: 5px;
        border-bottom: solid 1px #dddddd;
    }
    .list-fields {
        margin-top: 20px;
        .field-item {
            flex: 1;
            margin-right: 30px;
            .field-title {
                font-size: 18px;
                font-weight: 600;
                color: #0c77c8;
                margin-bottom: 30px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        .form-group {
            &.radio {
                label {
                    position: relative;
                    padding: 0;
                    left: unset;
                }
            }
            flex: 1;
            position: relative;
            margin-right: 30px;
            .form-control {
                border-radius: 4px;
                border: solid 1px #dddddd;
                height: 50px;
            }
            label {
                position: absolute;
                top: -12PX;
                left: 15px;
                background-color: #fff;
                padding: 0px 10px;
            }
            &:last-child {
                margin-right: 0;
            }
            &.select-field::after {
                content: "";
                position: absolute;
                top: 22px;
                right: 10px;
                width: 0px;
                height: 0px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #000000;
            }
        }
        .composite-input {
            margin-bottom: 30px;
            flex: 1;
            .form-group {
                margin-right: 0;
                &.input-field {
                    flex: 1;
                    .form-control {
                        border-top-right-radius: 0px;
                        border-bottom-right-radius: 0px;
                    }
                }
                &.select-field {
                    width: 100px;
                    flex: unset;
                    .form-control {
                        padding-right: 30px;
                        background-color: #eee;
                        border-top-left-radius: 0px;
                        border-bottom-left-radius: 0px;
                    }
                }
            }
        }
    }
}
