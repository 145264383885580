.cno-user-detail {
    border-radius: 4px;
    border: solid 1px rgba(151, 151, 151, 0.36);
    padding: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    .cno-block-user {
        .cno-user-icone {
            margin-right: 30px;
            img {
                height: 95px;
            }
        }
        .cno-user-desc {
            flex: 1;
            .user-line {
                margin-bottom: 30px;
                .item-line {
                    margin-right: 10px;
                    flex: 1;
                    .line-name {
                        font-size: 22px;
                        font-weight: 600;
                        width: 150px;
                        color: #197eca;
                    }
                    .line-value {
                        font-size: 20px;
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
