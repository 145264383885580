@import "./assets/SCSS/base";
@import "./assets/SCSS/font";
@import "./assets/SCSS/main.scss";
@import "./assets/SCSS/user";
@import "./assets/SCSS/form";


body {
  line-height: .9!important;
  padding: 0%;
  margin: 0%;
  overflow: hidden;
  label, span {
    font-weight: 600;
  }
}

.right > form {
  width: 50%;
  & > .search-bar {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      padding: 0.5rem 1.7rem;
      width: 90%;
      border: 1px solid #9a9a9a;
      outline: none;
      font-size: .9vmax;
      color: #06114695;
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
      height: 2.5rem;
    }
   & > em {
    position: absolute;
    left: 3%;
    color: #9a9a9a;

   }
   button {
    color: #9a9a9a;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    border: 1px solid #9a9a9a;
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    em {
      // position: absolute;
      color: #9a9a9a;
      // right: 2%;
      // top: 25%;
    }
   }
  }
}

.custom-select {
  font-size: .85rem!important;
}


.btn {
  box-shadow: none!important;
}

.form-control {
  outline: none;
  box-shadow: none!important;
}


.avatar-content {
  font-weight: bold!important;
}

.ngx-pagination {
  margin-bottom: 0!important;
  li {
    margin-right: .5rem!important;
    a {
      border: 1px solid #ced4da;
      padding: 0.175rem 0.525rem!important;
      outline: none;
      color: #adb0b3!important;
    }

    span {
      font-size: 11px!important;
    }

    &.pagination-previous, &.pagination-next {
      a {
        border: none!important;
        padding: 0.1875rem 0.625rem!important;
      }
    }
  }
}


.ngx-pagination .current {
  background: #84ACFD!important;
}


.min-circle {
  width: 1vmax;
  height: 1vmax;
  border-radius: 50%;
}

.pcontrols {
  height: 3vh;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.indicator_one {
  background-color: #84ACFD!important;
}
.indicator_two {
  background-color: #FDDC84!important;
}
.indicator_three {
  background-color:#FDBC84!important;
}
.indicator_four {
  background-color: #FD8484!important;
}

.flexible {
  display: flex;
  align-items: center;
}


// icons svg


.ui-chevron-left {
  background-image: url('./assets/icons/chevron-left.svg');
  background-size: 2.5vw;
  padding: 2.5vw;
  background-repeat: no-repeat;
  background-position: center;
}

.ui-bars {
  background-image: url('./assets/icons/bars.svg');
  background-size: 2.5vw;
  padding: 2.5vw;
  background-repeat: no-repeat;
  background-position: center;
}

.ui-d-chevron {
  background-image: url('./assets/icons/double-chevron.svg');
  background-size: 1.2vw;
  padding: 1.2vw;
  background-repeat: no-repeat;
  background-position: center;
}


.mg-2 {
  margin-block: 4vh;
}


.avatar-circle {
  margin-left: 1rem;
  width: 2.5vmax;
  height: 2vmax;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    width: 100%;
    height: 100%;
  }
  &>span{
    color: #ffffff;
    font-weight: bolder;
    font-size: medium;
    font-family:  Helvetica, sans-serif;
    text-transform: uppercase;
  }
}

.my-y {
  margin-top: 4.5vh;
  margin-bottom: 4.5vh;
}

.h-line {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  button {
    width: 15vw;
    border: none;
    height: 6vh;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    span {
      color: #061146;
      font-size: .95vmax;
      font-weight: 600;
      margin-left: 1vmax;
      text-transform: capitalize;
    }

    em {
      font-size: 1.8vmax;
      // font-weight: 600;
      color: #061146;
    }
  }
}


[data-title] {
  position: relative;

  &:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

  &:after {
    content: attr(data-title);
    position: absolute;
    bottom: 2%;
    right: 100%;
    padding: 4px 4px 4px 8px;
    white-space: nowrap;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 0px 2px rgba(0,0,0,.15);
    -webkit-box-shadow: 0px 0px 2px rgba(0,0,0,.15);
    box-shadow: 0px 0px 2px rgba(0,0,0,.15);
    opacity: 0;
    z-index: 99999;
    visibility: hidden;
    font-size: .8vmax;
    background-color: #171C2E;
    color: #FFFF;
  }
}


i,button,a {
  cursor: pointer;
}

.unavailable {
  background-color: #f8f8f8;
}


select.form-control {
  font-size: 1vmax;
    option {
      font-size: 1vmax;
    }

&::-webkit-scrollbar {
  width: .7vmax;
}

&::-webkit-scrollbar-track {

  background-color: transparent;
  border-radius: 2rem;
}

&::-webkit-scrollbar-thumb {
  background-color: #061146;
  border: 1.5px solid #FFF;
  border-radius: 2rem;

}
}



.loading {
  width: 100%;
  height: .3vmax;
  background-color: #84acfd;
  animation: 1.3s linear infinite alternate load;
}


@keyframes load {
  0% { width: 0;}
  50% { width: 50%;}
  100% { width: 100%;}
}


.index {
  padding-bottom: 0!important;
}


.ready {
  background-color:#34A853;
}

.pending {
  background-color: #FBBC2C;
}

.rejected {
  background-color: #ff6b6b;
}

.ready-txt {
    color: #34A853 !important;
}

.pending-txt {
    color: #FBBC2C !important;
}

.rejected-txt {
    color: #ff6b6b !important;
}
.active-button {
  background-color: #84ACFD !important;
  color: #fff !important;
}

.display-flex {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}



button:disabled {
  opacity: .65!important;
}


body.modal-open ._container{
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
}




.form-control {
  font-size: .9vmax!important;
  height: 2.5rem!important;
}


.error-alert {
  font-size: .8vmax;
  color: red;
}


.modal-backdrop {
  background-color: #080E3E!important;
  // background-color: #061146!important;
}

.popup-right-side {
  position: absolute !important;
  right: 17rem !important;
  top: 2px !important;
  margin-top: 1px !important;
  width: 48rem !important;
  height: 99vh;
}

.popup-right-side > .modal-content {
  height: 100%;
  width: 48rem !important;
}

.popup-right-side-mini {
  position: absolute !important;
  right: 14rem !important;
  top: 2px !important;
  margin-top: 1px !important;
  width: 45rem !important;
  height: 99vh;
}

.popup-right-side-mini > .modal-content {
  height: 100%;
  width: 45rem !important;
}
 modal-container {background:#486c9d70!important}

 .popup-right-side-small {
  position: absolute !important;
  right: 0rem !important;
  top: 0px !important;
  margin-top: 0px !important;
  width: 30rem !important;
  height: 100vh;
}

.popup-right-side-small > .modal-content {
  height: 100%;
  width: 30rem !important;
}

.popup-right-side-xl {
  position: absolute !important;
  right: 27rem !important;
  top: 2px !important;
  margin-top: 1px !important;
  width: 55rem !important;
  height: 99vh;
}

.popup-right-side-xl > .modal-content {
  height: 100%;
  width: 58rem !important;
}


._actions{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  button {
    width: 89%;
    height: 100%;
    background: #517bbd 0% 0% no-repeat padding-box;
    border: #517bbd solid 1px;
    border-radius: 2px;
    opacity: 1;
    color: white;
    &:hover {
      color: #FFF;
    }
  }
}


.input-red{
  border: solid 1px #ff6b6b !important;
  outline: none !important;
  outline-style: none !important;
box-shadow: none !important;
  &:focus{
  outline: none;
    border: solid 1px #ff6b6b !important;
  }
   &:focus-visible{
  outline: none !important;
    border: solid 1px #ff6b6b !important;
  }
}
