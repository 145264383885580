.cno-block-title {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 81px;
  width: 100%;
  border-bottom: solid 1px #dddddd;
  color: #0091ff;
  z-index: 24;
  background-color: #ffffff;
  .container {
    margin: 10px !important;
  }
}
.cno-block-title {
  .options{
    color: #0091ff;
  }
}

.cno-tab {
    margin-bottom: 15px;
    .item-tab {
        width: 140px;
        height: 40px;
        font-size: 13px;
        color: #a8a8a8;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        box-shadow: inset 1px 0 0 0 #eeeeee, inset -1px 0 0 0 #eeeeee, inset 0 1px 0 0 #eeeeee;
        background-color: #f8f8f8;
        cursor: pointer;
        &.active {
            background-color: rgba(18, 75, 131, 0.27);
            color: #59626a;
        }
    }
}
.cno-block{
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border: solid 1px #979797;
  //background-color: #ffffff;
  margin-bottom: 10px;
  background: #f8f8f8
}

.cno-block-table {
    margin-top: 10px;
    padding: 20px 15px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    border: solid 1px #979797;
    background-color: #ffffff;
    .cno-block-table-header {
        margin-bottom: 20px;
        .cno-block-table-title {
            font-size: 20px;
        }
        .cno-block-table-search {
            position: relative;
            &::after {
                content: url("/assets/icones/search.png");
                width: 20px;
                position: absolute;
                right: 10px;
                top: 8px;
            }
            input {
                width: 250px;
                border-radius: 4px;
                border: solid 1px #979797;
            }
        }
    }
    table {
        text-align: center;
        margin-bottom: 25px;
        thead {
            background-color: var(--primary-color);
            color: #ffffff;
            font-size: 20px;
            th {
                vertical-align: middle;
            }
        }
        tr {
            height: 55px;
            td {
                vertical-align: middle;
                &.td-action {
                    a {
                        margin-right: 15px;
                        img {
                            height: 15px;
                        }
                    }
                }
            }
        }
    }
}

.cno-block-charts {
    margin-bottom: 35px;
    .bar-charts {
        width: 60%;
        padding: 10px;
        border-radius: 3px;
        border: solid 1px #d4dce3;
    }
    .pie-chart {
        width: 40%;
        .cno-block-pie-chart {
            border-radius: 3px;
            border: solid 1px #d4dce3;
          height: 100%;
            .item {
                flex: 1;

            }
        }
    }
}

.page-item.active .page-link {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.page-link {
    color: #4a90e2;
}

.form-control:focus {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 0.25rem rgba(18, 75, 131, 0.25);
}
